import { EN_GB } from '@clearscore/config.i18n';
import i18next from 'i18next';

import ChangePasswordForm from './change-password-form';
import copy from './copy/component/change-password-form/en-gb/copy.json';
import { COMPONENT_NAMESPACE } from './lib/constants';

export default ChangePasswordForm;

i18next.addResourceBundle(EN_GB, COMPONENT_NAMESPACE, copy);
