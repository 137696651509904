import { constants as alertConstants } from '@clearscore/redux.alerts';
import { types as userTypes } from '@clearscore/redux.users';
import Text from '@clearscore/rainbow.text';
import i18next from 'i18next';
import { EN_GB } from '@clearscore/config.i18n';

import { ERROR_IDENTICAL_PASSWORDS, PAGE_NAMESPACE } from './constants';

const t = i18next.getFixedT(EN_GB, PAGE_NAMESPACE);

const alertMessageWithComponents = (message, props) => ({
    message,
    alertType: alertConstants.ERROR,
    Components: [{ Component: Text.Link, props: { ...props, key: 'link' } }],
    isDismissible: true,
});

const alertWithMessage = (message) => ({
    message,
    alertType: alertConstants.ERROR,
    isDismissible: true,
});

export default {
    [userTypes.USERS_RESET_PASSWORD_ERROR]: ({ payload: { data } }) => {
        const [{ code }] = data.errors;

        if (code === ERROR_IDENTICAL_PASSWORDS) {
            return {
                ...alertMessageWithComponents(t('alertErrorIdenticalPasswords'), {
                    isNewTab: true,
                    href: `https://www.clearscore.com/credit-score/how-to-protect-yourself-against-identity-fraud`,
                }),
            };
        }

        return alertWithMessage(t('alertError'));
    },
};
