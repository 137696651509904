import React from 'react';
import { PropTypes } from 'prop-types';
import getDevice from '@clearscore/helpers.device';
import Text from '@clearscore/rainbow.text';
import RainbowContainer from '@clearscore/rainbow.container';
import { useTranslation } from 'react-i18next';

import ChangePasswordForm from './components/change-password-form';
import style from './index.module.css';
import { PAGE_NAMESPACE } from './lib/constants';

const ChangePassword = ({
    match: {
        params: { market, token },
    },
    handleSubmit,
}) => {
    const { t } = useTranslation(PAGE_NAMESPACE);

    const handleReset = (resetData) =>
        handleSubmit(
            {
                token,
                device: getDevice(),
                password: resetData.password,
            },
            market,
        );

    return (
        <div className={style.formWrapper}>
            <RainbowContainer size={RainbowContainer.Size.SMALL}>
                <Text.H1>{t('mainTitle')}</Text.H1>
                <ChangePasswordForm market={market} handleReset={handleReset} />
            </RainbowContainer>
        </div>
    );
};

ChangePassword.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            token: PropTypes.string,
            market: PropTypes.string,
        }),
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default ChangePassword;
