import createValidator from '@clearscore/validation.create';
import { isRequiredValidation } from '@clearscore/validation.is-required';
import { isStrongPasswordValidation } from '@clearscore/validation.is-strong-password';
import { matchPasswordValidation } from '@clearscore/validation.match-password';

const MIN_LENGTH = 8;
const MAX_LENGTH = 50;

const createValidation = ({ relaxedRequirements }) =>
    createValidator({
        password: [
            isRequiredValidation(),
            isStrongPasswordValidation(' ', { relaxedRequirements })(MIN_LENGTH, MAX_LENGTH),
        ],
        confirmPassword: [isRequiredValidation(), matchPasswordValidation()('password')],
    });

export default createValidation;
