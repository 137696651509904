import connect from '@clearscore/redux.connect';
import { bindActionCreators } from 'redux';
import { actions as usersActions } from '@clearscore/redux.users';
import withVertical from '@clearscore-group/ui.external-cs-auth.vertical';

import ChangePassword from './change-password';
import epics from './lib/epics';
import reducer from './lib/reducer';

const mapDispatch = (dispatch) => bindActionCreators({ handleSubmit: usersActions.resetPassword }, dispatch);

const ConnectedComponent = connect(null, mapDispatch, { useRouter: true })(ChangePassword);

export default withVertical(ConnectedComponent, {
    name: 'webapp.change-password',
    reducer,
    epics,
});
