import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import Button from '@clearscore/rainbow.button';
import Label from '@clearscore/rainbow.label';
import Password from '@clearscore/rainbow.password';
import { useTranslation } from 'react-i18next';

import marketConfig from './lib/market-config';
import validate from './lib/validator';
import styles from './index.module.css';
import { COMPONENT_NAMESPACE } from './lib/constants';

function ChangePasswordForm({ handleReset, market }) {
    const { relaxedRequirements, requirements } = marketConfig({ market });
    const validator = validate({ relaxedRequirements });
    const { t } = useTranslation(COMPONENT_NAMESPACE);

    return (
        <Form
            onSubmit={handleReset}
            validate={validator}
            render={({ handleSubmit, pristine, invalid, submitting }) => (
                <form onSubmit={handleSubmit}>
                    <Field name="password">
                        {({ input, meta }) => (
                            <div className={styles.field}>
                                <Label htmlFor={input.name}>{t('password.label')}</Label>
                                <Password
                                    id={input.name}
                                    name={input.name}
                                    value={input.value}
                                    onChange={input.onChange}
                                    onBlur={input.onBlur}
                                    isValid={meta.touched && meta.valid}
                                    isInvalid={meta.touched && meta.invalid}
                                    errorMessage={(meta.touched && meta.error) || ''}
                                    placeholder={t('password.placeholder')}
                                    requirements={requirements}
                                />
                            </div>
                        )}
                    </Field>
                    <Field name="confirmPassword">
                        {({ input, meta }) => (
                            <div className={styles.field}>
                                <Label htmlFor={input.name}>{t('confirmPassword.label')}</Label>
                                <Password
                                    id={input.name}
                                    name={input.name}
                                    value={input.value}
                                    onChange={input.onChange}
                                    onBlur={input.onBlur}
                                    isValid={meta.touched && meta.valid}
                                    isInvalid={meta.touched && meta.invalid}
                                    errorMessage={(meta.touched && meta.error) || ''}
                                    placeholder={t('confirmPassword.placeholder')}
                                />
                            </div>
                        )}
                    </Field>
                    <Button
                        htmlType="submit"
                        dataId="submit"
                        isResponsive
                        type={Button.types.PRIMARY}
                        size={Button.sizes.LARGE}
                        isDisabled={pristine || invalid}
                        isLoading={submitting}
                    >
                        {t('submit.label')}
                    </Button>
                </form>
            )}
        />
    );
}

ChangePasswordForm.propTypes = {
    market: PropTypes.string.isRequired,
    handleReset: PropTypes.func.isRequired,
};

export default ChangePasswordForm;
