import { GB, ZA, AU } from '@clearscore/config.i18n';
import hasLowerCase from '@clearscore/validation.has-lower-case';
import hasUpperCase from '@clearscore/validation.has-upper-case';
import hasNumber from '@clearscore/validation.has-number';
import minLength from '@clearscore/validation.min-length';
import maxLength from '@clearscore/validation.max-length';
import i18next from 'i18next';

import { COMPONENT_NAMESPACE } from './constants';

export default ({ market = GB }) => {
    const t = i18next.getFixedT(market, COMPONENT_NAMESPACE);
    switch (market) {
        case AU:
        case ZA:
            return {
                relaxedRequirements: true,
                requirements: [
                    {
                        id: 'range',
                        text: t('password.errors.betweenRange'),
                        validator: (value) => minLength(8)(value) && maxLength(50)(value),
                    },
                ],
            };
        default:
        case GB:
            return {
                relaxedRequirements: false,
                requirements: [
                    {
                        id: 'upper-case-lower-case',
                        text: t('password.errors.upperAndLowerCase'),
                        validator: (value) => hasLowerCase(value) && hasUpperCase(value),
                    },
                    {
                        id: 'at-least-one-number',
                        text: t('password.errors.atLeastOneNumber'),
                        validator: hasNumber,
                    },
                    {
                        id: 'range',
                        text: t('password.errors.betweenRange'),
                        validator: (value) => minLength(8)(value) && maxLength(50)(value),
                    },
                ],
            };
    }
};
